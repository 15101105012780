import React, { Component } from 'react';
import Navigator from '../../components/navigator/navigator.component';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { GALLERY } from '../../data/gallery';
import Footer from '../../components/footer/footer.component';

class Gallery extends Component {
  render() {
    return (
      <div className="h-full w-full flex lg:flex-row flex-col">
        <Navigator {...this.props} />
        <div className="lg:h-full w-full shift-mobile-contact-background bg-cover bg-repeat-y lg:overflow-auto resize-y flex-row">
          <div className="lg:h-body-container pictures-gallery">
            <ImageGallery
              className="w-full"
              items={GALLERY.slider}
              showNav={true}
              showThumbnails={true}
              showPlayButton={false}
              showBullets={false}
              showFullscreenButton={true}
              autoPlay={false}
              slideInterval={5000}
              thumbnailClass="gallery-thumbnail"
              disableThumbnailScroll={true}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Gallery;
