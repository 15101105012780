import React, { Component } from 'react';
import Navigator from '../../components/navigator/navigator.component';

import Footer from '../../components/footer/footer.component';
import { HOUSES } from '../../data/houses';
import HousesRow from '../../components/house-row/house-row.component';

class Houses extends Component {
  renderTitle = () => (
    <span>
      <h2 className="lg:text-3xl sm:lg">selecione</h2>
      <h2 className="lg:text-3xl sm:lg">um apartamento</h2>
      <div className="hidden lg:block border-theme-primary border bg-theme-primary radius-10 w-24 h-1 mt-4">
        &nbsp;
      </div>
    </span>
  );

  renderTitleMobile = () => (
    <span className="block lg:hidden w-5/6 m-auto min-h-fit-content mt-6">
      {this.renderTitle()}
    </span>
  );

  renderTitleDesktop = () => (
    <span className="hidden lg:block title-desktop absolute z-10 lg:flex flex-col sm:pt-10 text-white ml-5 lg:ml-menu-title lg:h-64 lg:w-1/4 sm:mt-10 lg:mt-80 lg:mt-0">
      {this.renderTitle()}
    </span>
  );

  render() {
    return (
      <div className="lg:h-full lg:h-full w-full flex lg:flex-row">
        <Navigator {...this.props} />
        {this.renderTitleDesktop()}
        <div className="w-full h-full lg:overflow-auto lg:mt-0">
          <div className="lg:h-body-container relative">
            <div className="relative houses-image lg:h-houses-map h-full min-h-fit-content">
              <img
                src={HOUSES.mainImage.path}
                alt={HOUSES.mainImage.description}
                className="h-half-screen lg:h-houses-map w-full"
              />
              {this.renderTitleMobile()}
              <HousesRow lot={HOUSES.housesDB.lot1}></HousesRow>
            </div>
          </div>
          <Footer className="relative pl-10" />
        </div>
      </div>
    );
  }
}
export default Houses;
