import React, { Component } from 'react';
import ContactForm from '../../components/contact-form';
import Navigator from '../../components/navigator/navigator.component';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/footer.component';
import { mailSender } from '../../config';

class ContactUs extends Component {
  const;
  renderTitle = () => {
    return (
      <span>
        <span>
          <h2 className="lg:text-3xl sm:lg">contactos</h2>
          <h1 className="lg:text-5xl sm:xl uppercase font-semibold mt-0 mb-5">
            esperamos por si
          </h1>
        </span>
        <Link to="houses" className="btn btn-menu">
          ver apartamentos
        </Link>
      </span>
    );
  };

  renderTitleMobile = () => (
    <span className="block text-white lg:hidden pt-16 pl-8">
      {this.renderTitle()}
    </span>
  );

  renderTitleDesktop = () => (
    <span className="hidden lg:block title-desktop absolute z-10 lg:flex flex-col sm:pt-10 sm: mt-10 lg:mt-72 text-white ml-menu-title lg:h-64 lg:w-1/3">
      {this.renderTitle()}
    </span>
  );

  render() {
    const defaultMessage =
      'Bom dia, gostaria de ser contactado a fim de obter mais informações sobre os vossos imóveis.';
    return (
      <div className="h-full w-full flex lg:flex-row flex-col">
        <Navigator {...this.props} />
        {this.renderTitleDesktop()}
        <div className="lg:h-full h-home-gallery w-full background-contact shift-mobile-contact-background bg-cover bg-repeat-y lg:overflow-auto resize-y flex-row">
          {this.renderTitleMobile()}
          <div className="h-body-container lg:pt-64 md:pt-64 sm:pt-12 sm:pl-8 sm:pr-8">
            <ContactForm
              subjectType={mailSender.infoKey}
              defaultMessage={defaultMessage}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ContactUs;
