import React, { Component } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { INFO } from '../../data/general-info';
import { Link } from 'react-router-dom';

class NotFoundComponent extends Component {
  render() {
    return (
      <div className="w-full h-full bg-theme-menu-bg">
        <img
          src={INFO.comingSoonImage}
          alt={INFO.comingSoonMessage}
          className="full-image"
        />
        <div className="absolute pin flex w-full h-full bg-black opacity-50" />
        <div className="absolute pin flex items-center justify-center ">
          <div className="flex flex-col items-center">
            <img src={INFO.logoHome} alt={INFO.logoHome} />
            <h2 className="text-white mt-20">{INFO.notFoundMessage}</h2>
            <Link to="/" className="btn mt-5">
              {INFO.notFoundHomeLink}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundComponent;
