import React, { Component } from 'react';
import 'react-image-gallery/styles/css/image-gallery.css';
import { INFO } from '../../data/general-info';

class ComingSoonComponent extends Component {
  render() {
    return (
      <div className="w-full h-full bg-theme-menu-bg overflow-hidden">
        <img
          src={INFO.comingSoonImage}
          alt={INFO.comingSoonMessage}
          className="full-image"
        />
        <div className="absolute pin flex w-full h-full bg-black opacity-50" />
        <div className="absolute pin flex items-center justify-center ">
          <div className="flex flex-col items-center">
            <img src={INFO.logoHome} alt={INFO.logoHome} />
            <h1 className="text-white mt-20 text-center">
              {INFO.comingSoonMessage}
            </h1>
            <h4 className="text-white mt-5 hidden md:block">
              {INFO.organizationEmail} <span className="ml-4 mr-4">|</span>
              {INFO.organizationPhone1} <span className="ml-4 mr-4">|</span>
              {INFO.organizationPhone2}
            </h4>
            <h4 className="text-white mt-5 block md:hidden text-center line-40">
              {INFO.organizationEmail} <br />
              {INFO.organizationPhone1} <br />
              {INFO.organizationPhone2}
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default ComingSoonComponent;
