import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { INFO } from '../../data/general-info';
import { HOME } from '../../data/home';
import Footer from '../../components/footer/footer.component';

class Home extends Component {
  renderVideo(item) {
    return (
      <div className="home-gallery h-screen w-screen">
        {
          <div className="relative h-screen w-screen">
            <iframe
              title="video"
              className="absolute w-full h-full"
              width="560"
              height="315"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            />
          </div>
        }
      </div>
    );
  }

  render() {
    return (
      <div className="h-screen">
        <div className="home-gallery h-body-container w-screen">
          <ImageGallery
            className="h-body-container w-full"
            items={HOME.slider}
            showNav={false}
            showThumbnails={false}
            showPlayButton={false}
            showBullets={true}
            showFullscreenButton={false}
            autoPlay={true}
            slideInterval={5000}
          />
          <div className="absolute pin flex items-center justify-center">
            <div className="flex flex-col items-center">
              <img
                src={INFO.logoHome}
                className="home-logo mb-12"
                alt={INFO.title}
              />
              <Link to="houses" className="btn">
                {INFO.mainAction}
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Home;
