import React, { Component } from 'react';
import { INFO } from '../../data/general-info';
import Modal from 'react-responsive-modal';
import ReactPlayer from 'react-player';

class VideoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showVideoModal: false,
      playing: false,
    };
  }
  render() {
    const modalstyles = {
      modal: {
        maxWidth: '85vw',
        width: '100%',
      },
    };

    const { showVideoModal } = this.state;
    const { buttonText } = this.props;
    return this.videoUrl ? (
      <div className="w-full">
        {this.renderButton(buttonText)}
        <Modal
          open={showVideoModal}
          onClose={() => this.handleVideoModalChange(false)}
          center
          styles={modalstyles}
        >
          <ReactPlayer url={this.videoUrl} width="100%" height="100%" playing />
        </Modal>
      </div>
    ) : (
      <div></div>
    );
  }

  get videoUrl() {
    return INFO.videoId;
  }

  handleVideoModalChange(showVideoModal) {
    let playing = false;
    if (showVideoModal) {
      playing = true;
    }
    this.setState({ showVideoModal, playing });
    this.props.onClick && this.props.onClick();
  }

  renderButton(buttonText) {
    if (!buttonText) {
      const alt = 'Botão de visualização do video do Condomínio Park 20.23';
      const onclick = () => this.handleVideoModalChange(true);
      const className = 'max-w self-center cursor-pointer';
      return (
        <div>
          <img
            onClick={onclick}
            src="img/icones/i-video.png"
            className={className}
            alt={alt}
          />
        </div>
      );
    } else {
      return (
        <button
          className="btn btn-with-icon"
          onClick={() => this.handleVideoModalChange(true)}
        >
          {buttonText}
        </button>
      );
    }
  }
}

export default VideoDialog;
