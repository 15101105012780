import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INFO } from '../../data/general-info';

class Footer extends Component {
  render() {
    return (
      <div className="bg-theme-menu-bg h-12 lg:h-footer relative pt-2 pl-10 text-white text-xs">
        <FontAwesomeIcon icon="copyright" /> {INFO.footerMsg}
      </div>
    );
  }
}

export default Footer;
