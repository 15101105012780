const getBluePrint = house => ({
  blueprints: [
    `img/houses/blueprint/house_${house}.png`,
    `img/houses/blueprint/house_${house}box.png`,
  ],
  blueprintsMob: [
    `img/houses/blueprint/house_${house}.png`,
    `img/houses/blueprint/house_${house}box.png`,
  ],
});

const houseSlider = [
  {
    original: 'img/houses/slider/Banner_1.jpg',
    thumbnail: 'img/houses/slider/Banner_1.jpg',
  },
  {
    original: 'img/houses/slider/Banner_2.jpg',
    thumbnail: 'img/houses/slider/Banner_2.jpg',
  },
  {
    original: 'img/houses/slider/Banner_3.jpg',
    thumbnail: 'img/houses/slider/Banner_3.jpg',
  },
  {
    original: 'img/houses/slider/Banner_4.jpg',
    thumbnail: 'img/houses/slider/Banner_4.jpg',
  },
  {
    original: 'img/houses/slider/Banner_5.jpg',
    thumbnail: 'img/houses/slider/Banner_5.jpg',
  },
  {
    original: 'img/houses/slider/Banner_6.jpg',
    thumbnail: 'img/houses/slider/Banner_6.jpg',
  },
];

export const HOUSES_IMAGES = houseSlider;

const housesDB = {
  lot1: {
    title: 'Tipologia T3',
    available: true,
    houses: [
      {
        title: 'apartamento A',
        status: 'sold',
        ...getBluePrint('a'),
        detailSlider: houseSlider,
      },
      {
        title: 'apartamento B',
        status: 'sold',
        ...getBluePrint('b'),
        detailSlider: houseSlider,
      },
      {
        title: 'apartamento C',
        status: 'available',
        ...getBluePrint('c'),
        detailSlider: houseSlider,
      },
      {
        title: 'apartamento D',
        status: 'sold',
        ...getBluePrint('d'),
        detailSlider: houseSlider,
      },
    ],
  },
};

export const HOUSES = {
  mainImage: {
    path: 'img/houses/main.jpg',
    description: 'Seleccionar apartamento',
  },
  housesDB,
};
