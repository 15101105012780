import React, { Component } from 'react';

class TermsConditions extends Component {
  render() {
    return (
      <div>
        <ol>
          <li className="mt-5 mb-5">
            No desenvolvimento de toda e qualquer atividade relacionada com o
            cumprimento do Contrato celebrado, a empresa "Linhas Invertidas,
            lda" obriga-se a cumprir as suas obrigações de acordo com o RGPD e
            nos termos da Legislação vigente em Portugal destinada à Proteção de
            Dados, empenhando-se em proceder a todo o tratamento de dados
            pessoais que venha a mostrar-se necessário ao desenvolvimento do
            Contrato no estrito e rigoroso cumprimento da Lei.
          </li>
          <li className="mt-5 mb-5">
            Ao abrigo do disposto no número anterior, a a empresa "Linhas
            Invertidas, Lda" obriga-se, nomeadamente:
            <ol type="a">
              <li className="mt-5 mb-5">
                Tratar e usar os dados pessoais nos termos legalmente
                permitidos, em especial recolhendo, registando, organizando,
                conservando, consultando ou transmitindo os mesmos, apenas e
                somente nos casos em que o seu titular tenha dado o
                consentimento inequívoco ou nos restantes legalmente previstos;
              </li>
              <li className="mt-5 mb-5">
                Tratar os dados de modo compatível com as finalidades para os
                quais tenham sido recolhidos;
              </li>
              <li className="mt-5 mb-5">
                Conservar os dados apenas durante o período necessário à
                prossecução das finalidades da recolha ou do tratamento
                posterior, garantindo a sua confidencialidade, destruindo
                qualquer cópia dos dados tratados que tenha na sua posse, seja
                em suporte informático ou documental, uma vez terminado o
                serviço;
              </li>
              <li className="mt-5 mb-5">
                Implementar as medidas técnicas e organizativas necessárias para
                proteger os dados contra a destruição, acidental ou ilícita, a
                perda acidental, a alteração, a difusão ou o acesso não
                autorizado, bem como contra qualquer outra forma de tratamento
                ilícito dos mesmos;
              </li>
              <li className="mt-5 mb-5">
                Informar imediatamente a outra Parte, devendo prestar toda a
                colaboração necessária a qualquer investigação que venha a ser
                realizada, caso exista alguma quebra de segurança, ou suspeita
                da mesma, independentemente de colocar ou não em causa a
                segurança e integridade dos Dados Pessoais;
              </li>
              <li className="mt-5 mb-5">
                Garantir o exercício, pelos respetivos titulares, dos respetivos
                direitos de informação, acesso e oposição;
              </li>
              <li className="mt-5 mb-5">
                Não comunicar os dados fornecidos a terceiras entidades;
              </li>
              <li className="mt-5 mb-5">
                Assegurar que os respetivos colaboradores ou os prestadores de
                serviços externos por si contratados e que venham a ter acesso a
                dados pessoais no contexto do Contrato cumprem as disposições
                legais aplicáveis em matéria de proteção de dados pessoais,
                designadamente, não cedendo ou divulgando tais dados pessoais a
                terceiros, nem deles fazendo uso para quaisquer fins que não os
                estritamente consentidos pelos respetivos titulares ou, se
                aplicável, ser o seu processamento objeto de notificação ou de
                pedido de autorização à Comissão Nacional de Proteção de Dados.
              </li>
              <li className="mt-5 mb-5">
                Responsabilizar-se por qualquer reclamação que possa ser
                apresentada (especialmente pela Comissão Nacional de Proteção de
                Dados) derivada de incumprimento que lhe seja imputável da
                legislação de proteção de dados pessoais e em especial das
                garantias previstas nestas clausulas, aceitando pagar o montante
                correspondente à multa, sanção, indemnização, danos e prejuízos,
                não obstante ter direito a reclamar ou impugnar tais decisões,
                às quais possa ser condenado o responsável pelo tratamento
                derivado do citado incumprimento;
              </li>
              <li className="mt-5 mb-5">
                A a empresa "Linhas Invertidas, Lda" indica como responsável
                pela Proteção de dados, o Dr. Bruno Marques, com escritório na
                R. DR. Manuel Arriaga Nº 28, 2º andar, 2670-451 Loures / TEL.
                FAX. 21 982 33 78.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    );
  }
}

export default TermsConditions;
