import React, { Component } from 'react';
import './App.css';
import ROUTERS from './data/routers';
import { HashRouter, Route, Switch } from 'react-router-dom';
import HttpsRedirect from './HttpsRedirect';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faCopyright,
  faTimes,
  faHome,
  faHotel,
  faMapMarkerAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import NotFoundComponent from './screens/not-found/not-found.component';
import ComingSoonComponent from './screens/coming-soon/coming-soon.component';
import { Helmet } from 'react-helmet';
import { INFO } from './data/general-info';

library.add(faTimes);
library.add(faBars);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faCopyright);
library.add(faHome);
library.add(faMapMarkerAlt);
library.add(faHotel);
library.add(faPhone);

class App extends Component {
  render() {
    return (
      <div className="App h-full w-full">
        <Helmet>
          <title>{INFO.title}</title>
          <meta name="description" content={INFO.description} />
        </Helmet>
        <HttpsRedirect>
          <HashRouter>
            {process.env.REACT_APP_AVAILABLE === 'false' ? (
              <Route path="*" exact={true} component={ComingSoonComponent} />
            ) : (
              <div className="h-screen w-screen">
                <Switch>
                  {ROUTERS.map(
                    ({ path, component: Comp, exact, hideMenu, data }, key) => (
                      <Route
                        key={key}
                        exact
                        path={path}
                        render={props => <Comp {...props} data={data} />}
                      />
                    ),
                  )}
                  <Route path="*" component={NotFoundComponent} />
                </Switch>
              </div>
            )}
          </HashRouter>
        </HttpsRedirect>
      </div>
    );
  }
}

export default App;
