import React, { Component } from 'react';
import ROUTERS from '../../data/routers';
import { Link, NavLink } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { INFO } from '../../data/general-info';
import VideoDialog from '../video-dialog/video-dialog.component';

const ICONES_PREFIX = 'img/icones/menu';
class Navigator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <span className="h-full lg:w-1/4 border-0">
        <div className="hidden h-full lg:block">{this._renderMenu()}</div>
        <div className="sm:flex block lg:hidden">
          <Menu
            isOpen={this.state.isOpen}
            onStateChange={state => this.handleStateChange(state)}
            width={'300px'}
            noOverlay
            disableOverlayClick
          >
            {this._renderMenu()}
          </Menu>
        </div>
      </span>
    );
  }

  _renderMenu() {
    const { active } = this.props;
    return (
      <div className="p-menu bg-theme-menu-bg flex h-full flex-col justify-start items-start">
        <Link to="/" className="self-center">
          <img
            src={INFO.logoMenu}
            className="menu-logo max-w self-center"
            alt={INFO.title}
          />
        </Link>
        <div
          className="pt-menu-video-player self-center w-logo-menu"
          onClick={() => this.handleStateChange({ isOpen: false })}
        >
          <VideoDialog />
        </div>
        <ul className="menu-list list-reset self-center w-logo-menu pt-menu">
          {ROUTERS.filter(item => !item.hideMenu).map((item, key) => {
            const className = active === item.name ? 'active' : '';
            return (
              <li className="border-b border-white" key={key}>
                <NavLink
                  to={item.path}
                  activeClassName="active"
                  className={className}
                >
                  {item.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <div className="w-logo-menu self-center">
          <img
            src={INFO.logoCompany}
            className="max-w pt-menu w-logo-company mt-10 opacity-50"
            alt={INFO.altCompany}
          />
        </div>
      </div>
    );
  }

  handleStateChange(state) {
    this.setState({ isOpen: state.isOpen });
  }

  getIcon(location, path, icon, active) {
    return location.pathname === path || active
      ? `${ICONES_PREFIX}/${icon}-selected.png`
      : `${ICONES_PREFIX}/${icon}.png`;
  }
}

export default Navigator;
