import React, { Component } from 'react';
import ContactForm from '../../components/contact-form';
import Navigator from '../../components/navigator/navigator.component';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../../components/footer/footer.component';
import { getFloorDescription } from '../../utils/houses';
import { mailSender } from '../../config';
import * as queryString from 'querystring';
import { HOUSES } from '../../data/houses';

class BookHome extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search.substr(1)); // removes ?
    const house = HOUSES.housesDB.lot1.houses[query.houseIndex];

    this.state = {
      query,
      queryString: props.location.search,
      house: {
        ...house,
        floorDescription: getFloorDescription(house.floor),
      },
    };
  }

  renderTitle = () => {
    return (
      <span>
        {!this.state.hideTitle && (
          <span>
            <h2 className="lg:text-3xl sm:lg">{this.state.house.title}</h2>
            <h1 className="lg:text-5xl sm:xl uppercase font-semibold mt-0 mb-5">
              Marcar Visita
            </h1>
          </span>
        )}
        <Link
          to={{
            pathname: '/houses',
            search: this.state.queryString,
          }}
          className="btn mt-5 btn-back"
        >
          <FontAwesomeIcon icon="chevron-left" /> regressar
        </Link>
      </span>
    );
  };

  renderTitleMobile = () => (
    <span className="block text-white lg:hidden pt-16 pl-8">
      {this.renderTitle()}
    </span>
  );

  renderTitleDesktop = () => (
    <span className="hidden lg:block title-desktop absolute z-10 lg:flex flex-col sm:pt-10 sm: mt-10 lg:mt-72 text-white ml-menu-title lg:h-64 lg:w-1/3">
      {this.renderTitle()}
    </span>
  );

  render() {
    const { lotTitle } = this.state.query;

    const defaultMessage = `Bom dia, gostaria de marcar uma visita ao apartamento ${this.state.house.title}.`;

    return (
      <div className="h-full w-full flex lg:flex-row flex-col">
        <Navigator active={'Apartamentos'} {...this.props} />
        {this.renderTitleDesktop()}
        <div className="lg:h-full h-home-gallery w-full background-book-home shift-mobile-contact-background sm:shift-background-mobile bg-cover bg-repeat-y lg:overflow-auto resize-y flex-row">
          {this.renderTitleMobile()}
          <div className="h-body-container lg:pt-64 md:pt-64 sm:pt-12 sm:pl-8 sm:pr-8">
            <ContactForm
              subjectType={mailSender.bookKey}
              house={this.state.house}
              lotTitle={lotTitle}
              defaultMessage={defaultMessage}
            />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default BookHome;
